import React, { useState, useEffect } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Button, Col, Row, Card } from 'antd';
import Prismic from 'prismic-javascript';
import PrismicDOM from 'prismic-dom';

import Layout from '../components/layout';
import SEO from '../components/seo';

const prismicURL = 'https://blackway.prismic.io/api/v2';

const Mapp = () => {
	const { Map, Marker, Tooltip, TileLayer } = require('react-leaflet');
	const L = require('leaflet');

	delete L.Icon.Default.prototype._getIconUrl;

	L.Icon.Default.mergeOptions({
		iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
		iconUrl: require('leaflet/dist/images/marker-icon.png'),
		shadowUrl: require('leaflet/dist/images/marker-shadow.png')
	});

	return (
		<Map center={[46.179960, 6.079830]} zoom={14}
		     style={{ width: '100%', height: '100%' }}
		     gestureHandling={true}>
			<TileLayer
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
			/>
			<Marker position={[46.179960, 6.079830]}>
				<Tooltip permanent>
					<strong>BLACK WAY SA</strong><br/>Route du Pré-Marais 22<br/>1233
					Bernex, Genève
				</Tooltip>
			</Marker>
		</Map>
	)
};

const Index = () => {
	const [ mapModal, setMapModal ] = useState(false);
	const [ popup, setPopup ] = useState(false);
	const [ popupMsg, setPopupMsg ] = useState('');
	const [ schedule, setSchedule ] = useState(null);

	useEffect(() => {
		var i, e, d = document, s = "script";i = d.createElement("script");i.async = 1;
		i.src = "https://cdn.curator.io/published/dd751d73-99e2-4900-92a4-9512ebb61729.js";
		e = d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);


		Prismic.getApi(prismicURL).then((api) => api.query(
			Prismic.Predicates.at('document.type', 'info_popup')
		))
			.then(response => {
				setPopup(response.results[0].data.active === 'On');
				setPopupMsg(PrismicDOM.RichText.asHtml(response.results[0].data.message));
			});

		Prismic.getApi(prismicURL).then((api) => api.query(
			Prismic.Predicates.at('document.type', 'horaires')
		))
			.then(response => {
				setSchedule(PrismicDOM.RichText.asHtml(response.results[0].data.horaires));
			});


		document.addEventListener('click', closeMapModal, false);
		return () => document.removeEventListener('click', closeMapModal, false);
	}, []);

	const closeMapModal = event => {
		if (mapModal && event.target.closest('.map-modal') === null)
			setMapModal(false);
	};

	return (
		<StaticQuery
			query={graphql`
		      query {
		        bgHome1: file(relativePath: { eq: "bg-home-1.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 1920) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        bgHome2: file(relativePath: { eq: "bg-home-2.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 1920) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        bgHome3: file(relativePath: { eq: "bg-home-3.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 1920) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        bgHome4: file(relativePath: { eq: "bg-home-4.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 1920) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        bgHome5: file(relativePath: { eq: "bg-home-5.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 1920) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        jessedesign: file(relativePath: { eq: "jessedesign.png" }) {
		          childImageSharp {
		            fluid(maxWidth: 150) {
			          ...GatsbyImageSharpFluid
			        }
		          }
		        }
		        gaidin: file(relativePath: { eq: "gaidin.png" }) {
		          childImageSharp {
		            fluid(maxWidth: 150) {
			          ...GatsbyImageSharpFluid
			        }
		          }
		        }
		        gvacomposites: file(relativePath: { eq: "gvacomposites.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 150) {
			          ...GatsbyImageSharpFluid
			        }
		          }
		        }
		        tdartskustom: file(relativePath: { eq: "tdartskustom.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 150) {
			          ...GatsbyImageSharpFluid
			        }
		          }
		        }
		        twinspeedshop: file(relativePath: { eq: "twinspeedshop.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 150) {
			          ...GatsbyImageSharpFluid
			        }
		          }
		        }
		        motorex: file(relativePath: { eq: "motorex.png" }) {
		          childImageSharp {
		            fluid(maxWidth: 150) {
			          ...GatsbyImageSharpFluid
			        }
		          }
		        }
		        nammu: file(relativePath: { eq: "nammu.png" }) {
		          childImageSharp {
		            fluid(maxWidth: 150) {
			          ...GatsbyImageSharpFluid
			        }
		          }
		        }
	          }
		    `}
			render={data => (
				<Layout>
					<SEO
						title="Accueil"
						description="Créé en 1996, le garage Black Way Motorcycles est le spécialiste Harley-Davidson & Buell dans la région Genevoise, de l'entretien à la vente ou de la modification jusqu'à la construction de moto sur mesure."
						lang="fr"
						url="/"
					/>

					{
						popup && (
							<div className="popup" onClick={() => setPopup(false)}>
								<div dangerouslySetInnerHTML={{ __html: popupMsg }} />
								<p>L'équipe Black Way Motorcycles</p>
								<small><a>[ Fermer ]</a></small>
							</div>
						)
					}

					<div className="fullwidth-img">
						<Img
							className="page-background"
							fluid={data.bgHome1.childImageSharp.fluid}
							alt="BlackWay Motorcycles - Spécialiste Harley-Davidson & Buell à Genève"
							style={{
								maxWidth: '100%',
								maxHeight: '100%',
								objectFit: 'cover'
							}}
						/>
					</div>
					<div className="box-container">
						<div className="content-block">
							<div className="content-block-bg" />
							<h2>NEWS</h2>

							<div id="curator-feed">
								<a href="https://curator.io" target="_blank" className="crt-logo crt-tag">Powered by Curator.io</a>
							</div>
						</div>
					</div>
					<div className="fullwidth-img">
						<Img
							className="page-background"
							fluid={data.bgHome2.childImageSharp.fluid}
							alt="BlackWay Motorcycles - Spécialiste Harley-Davidson & Buell à Genève"
							style={{
								maxWidth: '100%',
								maxHeight: '100%',
								objectFit: 'cover'
							}}
						/>
					</div>
					<div className="box-container first">
						<div className="content-block">
							<div className="content-block-bg" />
							<h2>BLACK WAY MOTORCYCLES</h2>
							<div>
								<h3>PRÉSENTATION</h3>
								<p>Créé en 1996, le garage Black Way Motorcycles est le spécialiste Harley-Davidson dans la région Genevoise, de l'entretien à la vente ou de la modification jusqu'à la construction de moto sur mesure.</p>
								<p>Début 2017 le garage change de main et Jérémy devient le nouveau patron. L'équipe s'agrandit avec la venue de Benoît en tant que chef mécanicien en juin 2017.</p>
								<p>Black Way Motorcycles, c'est un petit garage local animé par une jeune équipe de passionnés qui s'efforcent de faire le maximum pour satisfaire les souhaits de leurs clients.</p>
							</div>
							<Row type="flex" justify="space-around" style={{ marginTop: 60 }} gutter={16}>
								<Col xs={24} md={12} xl={8} xxl={7}>
									<Link to="/garage">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>ÉQUIPE ET GARAGE</h3>
											<p>Découvrez nos locaux et la team Black Way</p>
											<Button type="primary" ghost>Découvrir le garage</Button>
										</Card>
									</Link>
								</Col>
								<Col xs={24} md={12} xl={8} xxl={7}>
									<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} onClick={() => setMapModal(true)} hoverable>
										<h3>LOCALISATION</h3>
										<p>Pour nous rendre visite : Route du Pré-Marais 22 à Bernex, Genève.</p>
										<Button type="primary" ghost>Voir la carte</Button>
									</Card>

									<div className={`map-modal ${mapModal ? 'active' : ''}`}>
										<div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
											{mapModal && <Mapp />}
										</div>
									</div>
								</Col>
								<Col xs={24} md={12} xl={8} xxl={7}>
									<Link to="/contact">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>HORAIRES & CONTACT</h3>
											<div className="schedules" dangerouslySetInnerHTML={{ __html: schedule }} />
											<Button type="primary" style={{ marginTop: '1rem' }} ghost>Contactez-nous !</Button>
										</Card>
									</Link>
								</Col>
							</Row>
						</div>
					</div>
					<div className="fullwidth-img">
						<Img
							className="page-background"
							fluid={data.bgHome3.childImageSharp.fluid}
							alt="BlackWay Motorcycles - Spécialiste Harley-Davidson & Buell à Genève"
							style={{
								maxWidth: '100%',
								maxHeight: '100%',
								objectFit: 'cover'
							}}
						/>
					</div>
					<div className="box-container">
						<div className="content-block">
							<div className="content-block-bg" />
							<h2>NOS PRODUITS</h2>

							<Row type="flex" justify="space-around" gutter={16}>
								<Col xs={24} md={12} xl={8} xxl={6}>
									<Link to="/customs">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>NOS RÉALISATIONS</h3>
											<p>Retrouvez toutes les réalisations sorties de notre atelier !</p>
											<Button type="primary" ghost>Découvrez nos réalisations</Button>
										</Card>
									</Link>
								</Col>
								<Col xs={24} md={12} xl={8} xxl={6}>
									<a href="https://www.harley-davidson.com/ch/fr/motorcycles/index.html" target="_blank" rel="noopener nofollow">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>VÉHICULES NEUFS</h3>
											<p>Retrouvez les différents modèles neufs d'Harley-Davidson.</p>
											<Button type="primary" ghost>Vers le site Harley-Davidson</Button>
										</Card>
									</a>
								</Col>
								<Col xs={24} md={12} xl={8} xxl={6}>
									<Link to="/occasions">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>VÉHICULES D'OCCASIONS</h3>
											<p>Retrouver toutes nos motos d'occasion.</p>
											<Button type="primary" ghost>Découvrez nos occasions !</Button>
										</Card>
									</Link>
								</Col>
							</Row>
						</div>
					</div>
					<div className="fullwidth-img">
						<Img
							className="page-background"
							fluid={data.bgHome4.childImageSharp.fluid}
							alt="BlackWay Motorcycles - Spécialiste Harley-Davidson & Buell à Genève"
							style={{
								maxWidth: '100%',
								maxHeight: '100%',
								objectFit: 'cover'
							}}
						/>
					</div>
					<div className="box-container">
						<div className="content-block">
							<div className="content-block-bg" />
							<h2>NOS SERVICES</h2>

							<Row type="flex" justify="space-around" gutter={16}>
								<Col xs={24} md={12} xxl={10}>
									<Link to="/garage">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>ENTRETIENS</h3>
											<p>Entretien, service, contrôles techniques, homologations et mise en place d'accessoire sur votre véhicule.</p>
											<Button type="primary" ghost>Voir tous les services</Button>
										</Card>
									</Link>
								</Col>
								<Col xs={24} md={12} xxl={10}>
									<Link to="/occasions">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>DÉPÔT VENTE</h3>
											<p>Système de dépot-vente avantageux pour votre véhicule.</p>
											<Button type="primary" ghost>Voir tous les services</Button>
										</Card>
									</Link>
								</Col>
							</Row>
						</div>
					</div>
					<div className="fullwidth-img">
						<Img
							className="page-background"
							fluid={data.bgHome5.childImageSharp.fluid}
							alt="BlackWay Motorcycles - Spécialiste Harley-Davidson & Buell à Genève"
							style={{
								maxWidth: '100%',
								maxHeight: '100%',
								objectFit: 'cover'
							}}
						/>
					</div>
					<div className="box-container last">
						<div className="content-block">
							<div className="content-block-bg" />
							<h2>PARTENAIRES</h2>

							<Row type="flex" justify="space-around" gutter={16}>
								<Col xs={24} md={12} xl={8}>
									<a href="https://jessedesign.ch/" target="_blank" rel="noreferrer noopener">
										<Card bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<Img
												className="page-background"
												fluid={data.jessedesign.childImageSharp.fluid}
												alt="JESSE DESIGN"
												style={{
													width: 150,
													marginBottom: 20,
													textAlign: 'center'
												}}
											/>
											<h3>JESSE DESIGN</h3>
											<p>Notre peintre préférer et attitrer.</p>
											<small>https://jessedesign.ch/</small>
										</Card>
									</a>
								</Col>
								<Col xs={24} md={12} xl={8}>
									<a href="http://www.gaidin.net/" target="_blank" rel="noreferrer noopener">
										<Card bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<Img
												className="page-background"
												fluid={data.gaidin.childImageSharp.fluid}
												alt="SEB GAIDIN"
												style={{
													width: 150,
													marginBottom: 20,
													textAlign: 'center'
												}}
											/>
											<h3>SEB GAIDIN</h3>
											<p>Celui qui met une touche «handmade» aux motos avec son savoir-faire en pinstriping.</p>
											<small>http://www.gaidin.net/</small>
										</Card>
									</a>
								</Col>
								<Col xs={24} md={12} xl={8}>
									<a href="https://gva-composites.ch/" target="_blank" rel="noreferrer noopener">
										<Card bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<Img
												className="page-background"
												fluid={data.gvacomposites.childImageSharp.fluid}
												alt="GVA COMPOSITES"
												style={{
													width: 150,
													marginBottom: 20,
													textAlign: 'center'
												}}
											/>
											<h3>GVA COMPOSITES</h3>
											<p>Spécialiste de la fibre de carbone et de verre, ainsi que les traitements pour les réservoirs d’essence.</p>
											<small>https://gva-composites.ch/</small>
										</Card>
									</a>
								</Col>
								<Col xs={24} md={12} xl={8}>
									<a href="https://nammu.ch/" target="_blank" rel="noreferrer noopener">
										<Card bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<Img
												className="page-background"
												fluid={data.nammu.childImageSharp.fluid}
												alt="NAMMU.CH"
												style={{
													width: 150,
													marginBottom: 20,
													textAlign: 'center'
												}}
											/>
											<h3>NAMMU.CH</h3>
											<p>Développeurs de sites et d'applications web.</p>
											<small>https://nammu.ch/</small>
										</Card>
									</a>
								</Col>
								<Col xs={24} md={12} xl={8}>
									<a href="https://www.motorex.com/fr-ch/" target="_blank" rel="noreferrer noopener">
										<Card bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<Img
												className="page-background"
												fluid={data.motorex.childImageSharp.fluid}
												alt="MOTOREX"
												style={{
													width: 150,
													marginBottom: 20,
													textAlign: 'center'
												}}
											/>
											<h3>MOTOREX</h3>
											<p>Les huiles Motorex c'est un partenariat de choix et de longue date qui était déjà utilisé avant les années 50 pour les motos Jenny crée par l’arrière-grand-père de Jérémy.</p>
											<small>https://www.motorex.com/fr-ch/</small>
										</Card>
									</a>
								</Col>
							</Row>
						</div>
					</div>
				</Layout>
			)}
		/>
	);
};

export default Index;
